/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable complexity */
/* eslint-disable no-param-reassign */
import Components from 'formiojs/components/Components';
import _ from 'lodash';

const DatagridComponent = (Components as any).components.datagrid;

class Datagrid extends (DatagridComponent as any) {
    init() {
        super.init();
        if (this.disabled || this.parentDisabled) {
            this.component.customClass = `datagrid-disabled-class ${this.component.customClass}`;
        }
    }

    checkRefresh(refreshData: any, changed: any) {
        const changePath = _.get(changed, 'instance.path', false);
        if (changePath && this.path === changePath) {
            this.rebuild();
        }
        if (
            refreshData === 'data' &&
            this.getValue().length &&
            changed?.instance?.parent.currentForm?.submissionSet
        ) {
            this.rebuild();
        }
    }

    calculateComponentValue(data: any, flags: any, row: any) {
        // Skip value calculation for the component if we don't have entire form data set
        if (_.isUndefined(_.get(this, 'root.data'))) {
            return false;
        }

        const allowOverride = _.get(
            this.component,
            'allowCalculateOverride',
            false,
        );

        const { dataValue } = this;
        // Calculate the new value.
        let calculatedValue = this.doValueCalculation(
            dataValue,
            data,
            row,
            flags,
        );

        if (_.isNil(calculatedValue)) {
            calculatedValue = this.emptyValue;
        }

        const changed = !_.isEqual(dataValue, calculatedValue);

        // Do not override calculations on server if they have calculateServer set.
        if (allowOverride) {
            // The value is considered locked if it is not empty and comes from a submission value.
            const fromSubmission =
                flags.fromSubmission && this.component.persistent === true;
            if (this.isEmpty(dataValue)) {
                // Reset the calculation lock if ever the data is cleared.
                this.calculationLocked = false;
            } else if (this.calculationLocked || fromSubmission) {
                this.calculationLocked = true;

                return false;
            }

            const firstPass = this.calculatedValue === undefined;
            if (firstPass) {
                this.calculatedValue = null;
            }
            const newCalculatedValue = this.normalizeValue(
                this.convertNumberOrBoolToString(calculatedValue),
            );
            const previousCalculatedValue = this.normalizeValue(
                this.convertNumberOrBoolToString(this.calculatedValue),
            );
            const normalizedDataValue = this.normalizeValue(
                this.convertNumberOrBoolToString(dataValue),
            );
            const calculationChanged = !_.isEqual(
                previousCalculatedValue,
                newCalculatedValue,
            );
            const previousChanged = !_.isEqual(
                normalizedDataValue,
                previousCalculatedValue,
            );

            if (calculationChanged && previousChanged && !firstPass) {
                return false;
            }

            // Check to ensure that the calculated value is different than the previously calculated value.
            if (
                previousCalculatedValue &&
                previousChanged &&
                !calculationChanged
            ) {
                return false;
            }

            if (flags.isReordered || !calculationChanged) {
                return false;
            }

            if (fromSubmission) {
                // If we set value from submission and it differs from calculated one, set the calculated value to prevent overriding dataValue in the next pass
                this.calculatedValue = calculatedValue;

                return false;
            }

            // If this is the firstPass, and the dataValue is different than to the calculatedValue.
            if (
                firstPass &&
                !this.isEmpty(dataValue) &&
                changed &&
                calculationChanged
            ) {
                // Return that we have a change so it will perform another pass.
                return true;
            }
        }

        this.calculatedValue = calculatedValue;

        if (changed) {
            if (!flags.noPristineChangeOnModified) {
                this.pristine = false;
            }

            flags.triggeredComponentId = this.id;

            return this.setValue(calculatedValue, flags);
        }

        return false;
    }
}

export default Datagrid;
